import React from 'react'
import { Provider, ResourcePicker } from '@shopify/app-bridge-react'

const VariantsPickerModal = (props) => {
  const {
    shop,
    host,
    open,
    onSelect = () => {},
    onClose = () => { console.log(`Must implement onClose in ProductPickerModal`)},
    initialProduct,
  } = props;

  return (
    <Provider
      config={{apiKey: process.env.GATSBY_SHOPIFY_APP_API_KEY, host: host }}
    >
      <ResourcePicker
        key={'variants'}
        allowMultiple={false}
        resourceType="Product"
        open={open}
        onCancel={onClose}
        showDraft={false}
        showArchived={false}
        onSelection={(selectPayload) => {
          onClose()
          onSelect(selectPayload)
        }}
        showVariants={true}
        initialSelectionIds={initialProduct}
      />
    </Provider>
  );
}

export default VariantsPickerModal;

